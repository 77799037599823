import React from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";


const Container = styled.div`





  
      center ${mobile({ width: "75%" })};
  
`;

const FormInput2 = ({ handleChange, label, ...otherProps }) => {
  return (
    <Container>
      {label && (
        <label>
          {label}
        </label>
      )}

      <input className="formInput" onChange={handleChange} {...otherProps} />
    </Container>
  );
}

export default FormInput2;