import React from 'react';


const UserProfile = props => {
  const { currentUser } = props;
  const { displayName } = currentUser;

  return (
    <div style={{
        display: "block",
        width: "100%",
        margin: "auto",
    }}>
      <ul style={{
            
            listStyleType: "none",}}>
        
       
          <div style={{
                
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
          }}>
            
          
       
        <li style={{
            display: "block",
            width: "100%",
        }}>
          <span style={{
               display: "flex",
               width: "100%",
               textAlign: "left",
               margin: "auto",
               fontSize: "20px",
               lineHeight: "1",
               textTransform: "uppercase",
           }}>
            {displayName  && displayName}
          </span>
        </li>
        </div>
      </ul>
    </div>
  );
}

export default UserProfile;