import { Facebook, MailOutline, Phone, Room, Instagram } from '@material-ui/icons';
import React from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";

const Container = styled.div`
 margin-bottom: 20px;
    
    ${mobile({ flexDirection: "column" })}
`;
const Container1 = styled.div`
    display: flex;
    margin-bottom: 40px;
    ${mobile({ flexDirection: "column" })}
    
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Logo = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Desc = styled.p`
    margin: 20px 0px;
    
    align-items: center;
    justify-content: center;
    align-text: center;
`;

const SocialContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SocialIcon = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;

    color: white;
    background-color: #${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
   
`;

const Center = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ display: "column" })}
`;


const Title = styled.h3`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

`;


const List = styled.ul`
    margin: 10;
    padding: 10;
    list-style: none;
    
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
`;

const ListItem = styled.li`
    width: 40%;
    margin-bottom: 8px;
    margin-right: 10px;
`;

const Right = styled.div`
    flex: 1;
    padding: 20px;
    align-items: center;
    justify-content: center;
    ${mobile({ backgroundColor: "#fff8f8" })}
`;

const ContactItem = styled.div`
margin-bottom: 35px;
display: flex;
align-items: center;

`;
const Bottom = styled.div`
    display: flex;
    margin-bottom: 80px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px; 
    
`;


const Footer = () => {
    return (
        <Container>
            <Container1>
            <Left>
                <Logo>DEKORANDO</Logo>
                <div style={{
                    alignItems: "center",
                    justifyContent: "center",
                    alignText: "center",
                    alignContent: "center",
                }}>
                <Desc>Negozio di colori, belle arti, decoupage, feltro e molto altro per la vostra voglia di creatività</Desc>
                </div>
                
                <SocialContainer>
                    <SocialIcon color="3B5999">
                        <a  style={{textDecoration: "none", color: "inherit", marginTop: 3}} href = "https://it-it.facebook.com/people/Dekorando-di-Renesto-Manuela/100057870090748/"  target="_blank" rel="noreferrer" >
                        <Facebook />
                        </a>
                        
                    </SocialIcon>
                    {/* <SocialIcon color="E4405F">
                        <Instagram/>
                    </SocialIcon> */}
                </SocialContainer>
            </Left>

            <Center>
                <Title>Orari negozio</Title>
                <List>
                    <ListItem>Lunedì	08:30–12:30, 15:30–19:15
                
                
                
                
                </ListItem>
                    <ListItem>Martedì:	08:30–12:30, 15:30–19:15</ListItem>
                    <ListItem>Mercoledì:	08:30–12:30, 15:30–19:15</ListItem>
                    <ListItem>Giovedì:	08:30–12:30, 15:30–19:15</ListItem>
                    <ListItem>Venerdì:	08:30–12:30, 15:30–19:15</ListItem>
                    <ListItem>Sabato:	08:30–12:30</ListItem>
                    <ListItem>Domenica:	Chiuso</ListItem>
                    
                    

                </List>
            </Center>

            <Right>
                <Title>Contatti</Title>
                <ContactItem >
                    <Room style={{marginRight: "10px"}}/>
                    
                    <a href="https://goo.gl/maps/2BMePxH67m5RReVEA" target="_blank" rel="noreferrer" style={{textDecoration: "none",  color: "inherit"}}>Via Cappuccina, 81 A, 30172 Mestre VE  </a>
                    
                </ContactItem>
                <ContactItem> 
                    <Phone style={{marginRight: "10px"}}/>
                    <a href="tel:041 531 4569" style={{textDecoration: "none",  color: "inherit"}}>041 531 4569</a>
                </ContactItem>

                <ContactItem> 
                
                <MailOutline style={{marginRight: "10px"}}/>
                <a href= "mailto:info@dekorando.it" style={{textDecoration: "none",  color: "inherit"}}>  info@dekorando.it
                </a>
                    
                </ContactItem>

                <ContactItem> 
                
                <h5>PARTITA IVA: 03827870274</h5>
                    
                </ContactItem>
                
                


            </Right>
            </Container1>
            

            <Bottom>
            Copyright 2022© Dekorando di Renesto Manuela
            </Bottom>
          

        </Container>

       
    );
}

export default Footer;
