import React, { useEffect } from 'react';
import {mobile} from "../responsive";
import  styled  from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from '../redux/Products/products.action';


const Wrapper = styled.div`
    
    display: flex;
    justify-content: space-around;
    
    ${mobile({  flexDirection:"column" })}


`;

const ImgContainer = styled.div`
    flex: 1;
`;

const Image = styled.img`
    margin-top: 120px;
    margin-left: 180px;
    max-width: 700px;
    max-height: 700px;
    object-fit: cover;
    margin-bottom: 30px;
    ${mobile({ maxHeight: "280px", maxWidth: "280px", marginTop: "50px", marginLeft: "10px"})}
`;

const InfoContainer = styled.div`
    flex: 1;
    
    padding: 110px 50px;
    ${mobile({ padding: "0px 0px", MinMarginRight: "150px"})}
`;

const Title1 = styled.h1`
    font-weight: 450;
    font-size: 60px;
    ${mobile({ fontWight: "100"})}
`;


const Title2 = styled.h1`
    font-weight: 300;
    font-size: 35px;
    ${mobile({ fontWight: "100"})}
`;



const Price = styled.span`
    font-weight: 100;
    font-size: 40px;
`;



const FilterTitle = styled.h1`
    font-size: 20px;
    font-weight: 200;
    margin-top: 20px
`;

const FilterColor = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
  
   
   margin: 5px;
    border: 4px solid black;
   
    background-color: ${props=>props.color};
    
    
    
`;

const description = styled.li`
width: 500px;


${mobile({ fontWight: "100"})}

`;



const mapState = state => ({
    product: state.productsData.product
  });

const ProductCard = ({}) => {

    
    const dispatch = useDispatch();
    const { productID } = useParams();
    const  { product }  = useSelector(mapState);

    

    

    const {
        productName,
        productPrice,
        productCategory,
        productPhoto,
        productColor1,
        productColor2,
        productColor3,
        productColor4,
        productColor5,
        productColor6,
        productColor7,
        productColor8,
        productColor9,
        productColor10,
        productSize,
        productDesc,
        productPriceType,
        

        
    } = product;

    useEffect(() => {
        dispatch(
            fetchProductStart(productID)
        )
        return () => {
            dispatch(
              setProduct({})
            )
          }
       
    }, []);

 
   

    return (
        <div style={{margin: "10px"}}>
         <Wrapper>
          <div>
                <ImgContainer>
                    <Image src={productPhoto}/>
                </ImgContainer>
                </div>
               



        <InfoContainer>
        <ul style={{listStyleType: "none"}}>
          <li>
            <Title1>
              {productName}
            </Title1>
          </li>
          {productCategory && <li>
            <Title2>
              Prodotto di {productCategory}
            </Title2>
          </li>}
          
          <li>
            <Price>
              € {productPrice} {productPriceType}
            </Price>
          </li>
          
          { productSize && (

          <FilterTitle>Misure: {productSize} </FilterTitle>
          )}



    { (productColor1 || productColor2 || productColor3 || productColor4 || productColor5 || productColor6 || productColor7 || productColor8 || productColor9 || productColor10) && (
           <div>
            <FilterTitle>Colore:</FilterTitle>
            <ul style={{display: "inline"}}>
                              <li style={{display: "inline"}} >
                             
                             { productColor1 && <FilterColor color={productColor1}></FilterColor>}
                           
                             { productColor2 && <FilterColor color={productColor2}></FilterColor>}

                             { productColor3 && <FilterColor color={productColor3}></FilterColor>}

                             { productColor4 && <FilterColor color={productColor4}></FilterColor>}

                             { productColor5 && <FilterColor color={productColor5}></FilterColor>}

                             { productColor6 && <FilterColor color={productColor6}></FilterColor>}

                             { productColor7 && <FilterColor color={productColor7}></FilterColor>}

                             { productColor8 && <FilterColor color={productColor8}></FilterColor>}

                             { productColor9 && <FilterColor color={productColor9}></FilterColor>}

                             { productColor10 && <FilterColor color={productColor10}></FilterColor>}
                             

                              
                                  
                              </li>    
              </ul>  

           </div>
             
           
       )}
        
          
          <description  >
            <span
              style={{margin: 20}}
              dangerouslySetInnerHTML={{ __html: productDesc }} />
          </description>

   
            
        
     
                   
           
        </ul>

   
      </InfoContainer>
        </Wrapper>

      
        </div>
    );
}

export default ProductCard;