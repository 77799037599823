



import React from 'react';
import { useSelector } from 'react-redux'
import UserProfile from './UserProfile';
import styled from "styled-components";
import {mobile} from "../responsive";


const Container = styled.div`
width: 100%;
height: 100%;
${mobile({ height: "50px" })}

`;

const Wrapper = styled.div`
margin: auto;
${mobile({ padding: "10px 0px" })}


`;














const mapState = ({ user }) => ({
  currentUser: user.currentUser
})

const VerticalNav = ({ children }) => {
  const { currentUser } = useSelector(mapState);

  const configUserProfile = {
    currentUser
  }

  return (
    <Container>

      <UserProfile {...configUserProfile} />

      <Wrapper>
        {children}
      </Wrapper>
    </Container>
  );
}

export default VerticalNav;