import React from 'react';
import  styled  from 'styled-components';
import Announcement from '../components/Announcement';
import Footer from '../components/Footer';

import ProductCard from '../components/ProductCard';



const Container = styled.div``;



const ProductDetails = ({}) => {
    return (
        <Container>
           
            <Announcement/>
            <ProductCard/>
            
            
            <Footer/>
        </Container>
    );
}

export default ProductDetails;

