import React from 'react';

import  styled  from 'styled-components';
import {mobile} from "../responsive";


const Container = styled.div`
    display: inline-block;
    width: 100%;
   

  
    ${mobile({ width: "100%" })};
  
`;


const Label1 = styled.label`
    display: block;
    width: 100%;
    text-align: left;
    
    ${mobile({ width: "100%" })}
`;


const Select1 = styled.select`
    display: block;
    width: auto;
    float: left;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 400;
    text-align: left;
    
    border: none;
    outline: none;
    cursor: pointer;
    
    ${mobile({ width: "100%" })}
`;


const FormSelect1 = ({ options, defaultValue, handleChange, label, ...otherProps }) => {
  if (!Array.isArray(options) || options.length < 1) return null;

  return (
    <Container>
      {label && (
        <Label1>
          {label}
        </Label1>
      )}

      <Select1 value={defaultValue} onChange={handleChange} {...otherProps}>
        {options.map((option, index) => {
          const { value, name } = option;

          return (
            <option key={index} value={value}>{name}</option>
          );
        })}
      </Select1>
    </Container>
  );
}

export default FormSelect1;