import React, { useState, useEffect } from 'react';
import { firestore } from './../firebase/utils';
import Modal from './../components/Modal';
import FormInput1 from './../components/FormInput1';
import FormSelect1 from './../components/FormSelect1';
import  styled  from 'styled-components';
import {mobile} from "../responsive";
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart, fetchProductsStart, deleteProductStart } from '../redux/Products/products.action';
import AdminLayout from '../layouts/AdminLayout';
import FormInput2 from './../components/FormInput2';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { CKEditor } from 'ckeditor4-react';
import { Search} from '@material-ui/icons';

const Container = styled.div`
    padding: 10px 10px;
  
      center 
  
`;

const CallToActions  = styled.div`
    height: 60%;
    width: 60%;
    padding: 40;
    margin: 40px 40px;
    
    
`;

const SearchContainer = styled.div`
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    marginRight: 150px;
    padding: 5px;
    
    ${mobile({ marginRight: "5px" })}
    
`;

const SearchBar = styled.input`
    border:none;
    outline: none;
    width: 1200px;
    ${mobile({ width: "400px",  })}
    
`;




const mapState = ({ productsData }) => ({
  products: productsData.products
});

const Admin = props => {
  const { products } = useSelector(mapState);
  const dispatch = useDispatch();
  
  const [hideModal, setHideModal] = useState(true);
  const [productCategory, setProductCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [productColor1, setProductColor1] = useState('');

  const [productColor2, setProductColor2] = useState('');

  const [productColor3, setProductColor3] = useState('');

  const [productColor4, setProductColor4] = useState('');

  const [productColor5, setProductColor5] = useState('');

  const [productColor6, setProductColor6] = useState('');

  const [productColor7, setProductColor7] = useState('');

  const [productColor8, setProductColor8] = useState('');

  const [productColor9, setProductColor9] = useState('');

  const [productColor10, setProductColor10] = useState('');
 
  const [clicked, setClicked] = useState(0)
  
  const [buttonOk, setButtonOk] = useState(false);

  
  const [productFile, setProductFile] = useState(null);
  
  const [productPhoto, setProductPhoto] = useState('');

  const [productPrice, setProductPrice] = useState('');

  const [productPriceType, setProductPriceType] = useState('');

  const [productDesc, setProductDesc] = useState('');

  const [productSize, setProductSize] = useState('');
 

  const [query, setQuery] = useState('');


  useEffect(() => {
    dispatch(
      fetchProductsStart()
    );
  }, []);

  const toggleModal = () => setHideModal(!hideModal);
  
   
  const configModal = {
    hideModal,
    toggleModal
  };

  const resetForm = () => {
    setHideModal(true);
    setProductCategory('');
    setProductName('');
    setProductPhoto('');
    setProductPrice('');
    setProductColor1('');
    setProductColor2('');
    setProductColor3('');
    setProductColor4('');
    setProductColor5('');
    setProductColor6('');
    setProductColor7('');
    setProductColor8('');
    setProductColor9('');
    setProductColor10('');
    setProductSize('');
    setProductFile(null);
    setClicked(0);
    setProductDesc('');
    setProductPriceType('');
    setButtonOk(false)
  };

  
  const handleSubmit = e => {
    e.preventDefault();
    
  

    dispatch(
        addProductStart({
            productCategory,
            productName,
            productPhoto,
            productPrice,
            productColor1,
            productColor2,
            productColor3,
            productColor4,
            productColor5,
            productColor6,
            productColor7,
            productColor8,
            productColor9,
            productColor10,
            productSize,
            productDesc,
            productPriceType,
            

            
           

        })
    );
    resetForm();

   
  };

  const handleClick = e => {
    e.preventDefault();

  
   setClicked(1)

   

    const fileName = new Date().getTime() + productFile.name;
    const storage = getStorage();
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, productFile);

    // Register three observers:
// 1. 'state_changed' observer, called any time the state changes
// 2. Error observer, called on failure
// 3. Completion observer, called on successful completion
uploadTask.on('state_changed', 
(snapshot) => {
  // Observe state change events such as progress, pause, and resume
  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  if (progress === 100) {
    setButtonOk(true);
  }
  console.log('Upload is ' + progress + '% done');
  switch (snapshot.state) {
    case 'paused':
      console.log('Upload is paused');
      break;
    case 'running':
      console.log('Upload is running');
      break;
      default:
  }
}, 
(error) => {
  // Handle unsuccessful uploads
}, 
() => {
  // Handle successful uploads on complete
  // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    setProductPhoto(downloadURL)
    console.log(downloadURL)
   
  });
}
);

  };

  
  

  return (
    <Container>
      <AdminLayout/>

      <CallToActions>
        
            <button  onClick={() => toggleModal()}>
              Aggiungi nuovo prodotto
            </button>
         
      </CallToActions>

      <SearchContainer>
            <SearchBar placeholder="Cerca prodotto"
            onChange={ e => setQuery(e.target.value.toLowerCase())}/>
            <Search style={{color: "gray", fontSize:16}}/>
      </SearchContainer>

      <Modal {...configModal}>
        <div style={{display: "flex"}}>
          <form onSubmit={handleSubmit}>

            <h2>
              Aggiungi nuovo prodotto
            </h2>
            
            <FormSelect1
              label="Categoria prodotto"
              
              options={[{
                value:"Nessuno",
                name:"Nessuno"
              }, {
                value: "Hobbystica Natale",
                name: "Hobbystica Natale"
              }, {
                value: "Hobbystica feltro, stoffe e gomma",
                name: "Hobbystica feltro, stoffe e gomma"
              }, {
                value: "Hobbystica prodotti vari",
                name: "Hobbystica prodotti vari"
              }, {
                value: "Hobbystica articoli da decorare",
                name: "Hobbystica articoli da decorare"
              }, {
                value: "Belle arti",
                name: "Belle Arti"
              }

            ]}
            required
              handleChange={e => setProductCategory(e.target.value)}
            />


            <FormInput1
              label="Nome prodotto"
              type="text"
              size="100"
              value={productName}
              required
              maxlength="45"
              
              handleChange={e => setProductName(e.target.value)}
            />


                <FormInput1
                label="Carica immagine prodotto da file"
                type="file"
                id="file"
                required
                
                onChange={e => setProductFile(e.target.files[0])}
                
                />
                 <button  style={{backgroundColor: clicked ? 'red' : 'white'}} onClick={handleClick} >
                  Carica immagine
                </button>
               


           
          
              
            

            <FormInput1
              label="Prezzo"
              type="number"
              min="0.00"
              max="10000000.00"
              step="0.01"
              
              value={productPrice}
              required
              handleChange={e => setProductPrice(e.target.value)}
            />

            <FormSelect1
              label="Tipologia prezzo"
              
              options={[{
                value:"Nessuno",
                name:"Nessuno"
              }, {
                value: "Cadauno",
                name: "Cadauno"
              }, {
                value: "Al metro",
                name: "Al metro"
              }

            ]}
            required
              handleChange={e => setProductPriceType(e.target.value)}
            />

            <h3 fontSize= "1.5rem">Descrizione prodotto 1</h3>
            <CKEditor
           
              onChange={evt => setProductDesc(evt.editor.getData())}
              
            />
            


            <br/>

           

            <div display="flex" flexDirection= "row"
              justifyContent= "center">

              <h3 fontSize= "1.5rem">Colore Prodotto</h3>

              <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor1}
              required
              handleChange={e => setProductColor1(e.target.value)}
            />


              <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor2}
              required
              handleChange={e => setProductColor2(e.target.value)}
            />      


              <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor3}
              required
              handleChange={e => setProductColor3(e.target.value)}
            />


            <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor4}
              required
              handleChange={e => setProductColor4(e.target.value)}
            />
           


           <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor5}
              required
              handleChange={e => setProductColor5(e.target.value)}
            />


            <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor6}
              required
              handleChange={e => setProductColor6(e.target.value)}
            />



            <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor7}
              required
              handleChange={e => setProductColor7(e.target.value)}
            />


            <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor8}
              required
              handleChange={e => setProductColor8(e.target.value)}
            />



            <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor9}
              required
              handleChange={e => setProductColor9(e.target.value)}
            />



            <FormInput2
              label for="favcolor"
              type="color"
              
              id="favcolor"
              name="favcolor" 
            
              value={productColor10}
              required
              handleChange={e => setProductColor10(e.target.value)}
            />

            
           <FormInput1
              label="Taglie prodotto"
              type="text"
              size="100"
              value={productSize}
              
              
              
              handleChange={e => setProductSize(e.target.value)}
            />


           <br/>
            
            
            </div>
          
         { buttonOk &&  <button  type="submit" style={{marginTop: "30px"}} >
              Aggiungi prodotto
            </button>}


            { !buttonOk &&  <button  type="submit" style={{marginTop: "30px"}} disabled >
              Aggiungi prodotto
            </button>}
              

          </form>
         
        </div>
      </Modal>

    <div  >
      <table width="100%" border="1" cellPadding="4" cellSpacing="0" >
        <tbody>
          <tr>
            <th>
              <h1>Gestione Prodotti</h1>
            </th>
          </tr>
          <tr>
            
            <td>
              <table className="results" border="1" cellPadding="20" cellSpacing="0" width="100%" >
              
              <tr>
                <th>Immagine</th>
                <th>Prodotto</th>
                <th>Categoria</th>
                <th>Prezzo</th>
                <th>Rimuovi</th>

              </tr>
  

                <tbody>
                  {products.filter((product) => product.productName.toLowerCase().includes(query)).map((product, index) => {
                    const {
                      productName,
                      productPhoto,
                      productPrice,
                      documentID,
                      productCategory,
                    } = product;
                    return (
                      <tr key={index}>
                        <td>
                          
                          <img src={productPhoto} width="150" max-height="150" alt=""/>
                        </td>
                        <td >
                          {productName}
                        </td>
                        <td>
                          {productCategory}
                        </td>


                        <td>
                         € {productPrice}
                        </td>
                        <td>
                          <button onClick={()=> dispatch(deleteProductStart(documentID))}>Rimuovi prodotto</button>
                        </td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  
   

    </Container>
  );
}

export default Admin;