import React from "react";
import Navbar from "../components/Navbar";

const MainLayout = props => {
    return (
        <div>
            <Navbar {...props}/>
            <div>
                {props.children}
            </div>
        </div>
    );
}

export default MainLayout;
