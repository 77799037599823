export const sliderItems =[
    {
        id:1,
        img: "https://www.stafil.com/wp-content/uploads/2021/03/foto-ambientata-bilderrahmen-1-e1617978011637.jpg",
        title: "Creazioni natalizie con i nostri prodotti",
        // desc: "SCONTI SUI NUOVI ARRIVI",
        bg: "f5fafd",
    },
    {
        id:2,
        img: "https://www.stafil.com/wp-content/uploads/2021/09/Welcome-autunno-1251x1536.jpg",
        title: "Creazioni autunnali con i nostri prodotti",
        // desc: "SCONTI SUI NUOVI ARRIVI",
        bg: "fcf1ed",
    },
    {
        id:3,
        img: "https://www.stafil.com/wp-content/uploads/2021/04/MG_8821-e1618912614829.jpg",
        title: "Creazioni primaverili con i nostri prodotti",
        // desc: "SCONTI SUI NUOVI ARRIVI",
        bg: "fbf0f4",
    },
    
];

// https://www.cwr.it/images/cwr/prodotti/hobby-e-creativita/prodotti-hobbistica-materiale-per-decoupage-800px__800x450.jpg
// https://www.puntocolormm.it/wp-content/uploads/2018/08/banner1.jpg
// https://www.casadelcolorepavan.com/c/54-category_default/linea-belle-arti.jpg


export const categories = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/dekorando-sito-web.appspot.com/o/dekorando.jpeg?alt=media&token=07ae013d-f5bc-4eb9-8318-f55e6fcbccfa",
    title: "BELLE ARTI",
    page: "/products/Belle arti",
},
    {
        id: 2,
        img: "https://firebasestorage.googleapis.com/v0/b/dekorando-sito-web.appspot.com/o/Hobbystica_Natale.jpeg?alt=media&token=37d5b384-d1ce-4e7f-99bf-7c34f12c1c1f",
        title: "HOBBYSTICA NATALE",
        page: "/products/Hobbystica Natale",
    },
   
    {
        id: 3,
        img: "https://firebasestorage.googleapis.com/v0/b/dekorando-sito-web.appspot.com/o/Hobbystica_Feltro_Stoffe.jpeg?alt=media&token=be795cb3-8b2a-4836-920b-3e653031a5fc",
        title: "HOBBYSTICA FELTRO, STOFFE E GOMMA",
        page: "/products/Hobbystica feltro, stoffe e gomma",
    },
   
    {
      id: 4,
      img: "https://firebasestorage.googleapis.com/v0/b/dekorando-sito-web.appspot.com/o/dekorando.jpeg?alt=media&token=07ae013d-f5bc-4eb9-8318-f55e6fcbccfa",
      title: "HOBBYSTICA PRODOTTI VARI",
      page: "/products/Hobbystica prodotti vari",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/dekorando-sito-web.appspot.com/o/Hobbystica_Arte_da_Decorare.jpeg?alt=media&token=4c357e75-24d7-47a2-9224-b61207a1d8a8",
    title: "HOBBYSTICA ARTICOLI DA DECORARE",
    page: "/products/Hobbystica articoli da decorare",
},
    {
      id: 6,
      img: "https://firebasestorage.googleapis.com/v0/b/dekorando-sito-web.appspot.com/o/dekorando.jpeg?alt=media&token=07ae013d-f5bc-4eb9-8318-f55e6fcbccfa",
      title: "TUTTI I PRODOTTI",
      page: "/products",
  },
];
export const popularProducts = [
  
]


