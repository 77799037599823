import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {mobile} from "../responsive";
import { useSelector } from "react-redux";
import { checkUserIsAdmin } from "../Utils";


const Container = styled.div`
    display: inline-block;
    width: 100%;
    background-color: black;
    height: 50px;
   
   
    
    ${mobile({ height: "50px" })}

`;

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const AdminToolbar = props => {
    const { currentUser } = useSelector(mapState);

    const isAdmin = checkUserIsAdmin(currentUser);
    if (!isAdmin) return null;
    return (
        <Container>
            <ul style={{float:"right"}}>
                <li style={{display:"inline-block"}}>
                    <Link style={{ textDecoration: 'none', color:"white", fontSize:"25px", marginRight: "60px", marginTop: "35px", position:"sticky", top:"0"}} to ="/admin">
                    Gestione Negozio
                    </Link>
                </li>
            </ul>
        </Container>
    );
}

export default AdminToolbar;