import { auth } from './../../firebase/utils';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const handleResetPasswordAPI = (email) => {
  const config = {
    url: 'https://dekorando-sito-web.web.app/login'
  };

  return new Promise((resolve, reject) => {
    auth.sendPasswordResetEmail(email, config)
      .then(() => {
        resolve();
        toast.success(`Un' email è stata inviata a ${email}. Clicca sul link per completare la procedura di reimpostazione della password`);
      })
      .catch(() => {
        const err = ['Email not found. Please try again.'];
        reject(err);
      });
  });
};