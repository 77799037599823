import React from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";


const Container = styled.div`
display: block;
width: 100%;
font-size: 1.5rem;
line-height: 1;
font-weight: 400;
text-align: left;
padding: 10px 5px;
margin: 10px auto;
border: 1px solid #9e9e9e;
outline: none;
  
      center ${mobile({ width: "75%" })};
  
`;

const FormInput1 = ({ handleChange, label, ...otherProps }) => {
  return (
    <Container>
      {label && (
        <label>
          {label}
        </label>
      )}

      <input className="formInput" onChange={handleChange} {...otherProps} />
    </Container>
  );
}

export default FormInput1;