import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOutUserStart} from '../redux/User/user.action';

import Navbar from '../components/Navbar';
import VerticalNav from '../components/VerticalNav';
import Footer from '../components/Footer';

const AdminLayout = props => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  return (
    <div className="adminLayout">
      
      <div className="controlPanel">
        <div className="sidebar"> 
            <div className="content">
                {props.children}
            </div>
          <VerticalNav>
           
            <ul style={{listStyleType: "none"}}>
              <li>
                <Link style={{ textDecoration: 'none', color:"black"}} to="/">
                  Home
                </Link>
                </li>
              <li>
                <span style={{cursor:"pointer"}} onClick={() => signOut()}>
                  Logout
                </span>
              </li>
              <li>
              <Link style={{ textDecoration: 'none', color:"black" }} to="/reset">
                  Modifica Password
                </Link>
              </li>
            </ul>
          </VerticalNav>
        </div>
       
      </div>
      
    </div>
  );
};

export default AdminLayout;

