
import React from 'react';
import styled from "styled-components";
import {mobile} from "../responsive";
import { Link } from 'react-router-dom';

const Info = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
`;

const Container = styled.div`
    flex: 1;
    margin: 5px;
    min-width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fbfd;
    position: relative;
    ${mobile({ margin: "0px" , height: "260px", width: "260px"})}

    &:hover ${Info}{
        opacity: 1;
    }

`;
const Container1 = styled.div`
    flex: 1;
    margin-bottom: 100px;
    
    margin-right: 5px;
    min-width: 300px;
    height: 300px;
    ${mobile({ marginRight: "2px", height: "260px", width: "260px", marginTop: "20px" })}
    
    
    position: relative;

   
`;


const Image = styled.img`
    max-height: 300px;
    max-width: 300px;
    z-index: 2;
    ${mobile({ maxHeight: "250px", maxWidth: "250px"})}
`;



const Product = ({
    documentID,
    productPhoto,
    productName,
    productPrice,
    productCategory,
    productPriceType,
    
}) => {
    


    if ( !documentID || !productPhoto || !productName || 
        typeof productPrice === 'undefined') return null;

        
    return (

        <Link to ={`/product/${documentID}`} style={{color: "black", textDecoration: "none"}}>
        <Container1>
        <Container>
           

            
 
            <Image src={productPhoto}/>
            
           
            
            <Info>
                
                
                
            </Info>
            
        </Container>
        
        
         
                <h3 style={{margin: "8px"}} >{productName}</h3>
              
               {productCategory && <h5 style={{margin: "8px"}} >Prodotto di {productCategory}</h5>}


              
           
                <h4 style={{margin: "8px"}}>€ {productPrice} {productPriceType}</h4>
           
            
        
            
            
       
        </Container1>
        </Link>
    );
}

export default Product;
