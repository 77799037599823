import React from "react";
import { Link } from "react-router-dom";


const Dashboard = props => {
    return (
        <div>
            <h1>
            Sei già loggato!   
            </h1>
        
            <h1>
            <Link to="/recovery" style={{ textDecoration: 'none', color:"black", margin:"auto", cursor:"pointer" }}>
                Modifica Password

            </Link>
            
            </h1>
        </div>
        
    );
};

export default Dashboard;
