export const firebaseConfig = {
  apiKey: process.env.React_App_FIREBASE_API,
  authDomain: "dekorando-sito-web.firebaseapp.com",
  projectId: process.env.React_App_PROJECT_ID,
  storageBucket: process.env.React_App_STORAGEBUCKET,
  messagingSenderId: process.env.React_App_MESSAGINGSENDERID,
  appId: process.env.React_App_APPID,
  measurementId: process.env.React_App_MEASUREMENTID
};

 