

import React from 'react';

import  styled  from 'styled-components';
import Announcement from '../components/Announcement';
import Products from '../components/Products';

import Footer from '../components/Footer';




const Container = styled.div`

`;



const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    outline: none;

`;






const ProductList = ({}) => {
    return (
        <Container>
            <Announcement/>
           <div style={{margin: 20}}>
           <h1>
                Prodotti della nostra vetrina
            </h1>
            </div>
        
            <FilterContainer>
            
                
            </FilterContainer>
            <Products/>

            <Footer/>
           
            
        </Container>
    );
}

export default ProductList;
