/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import Home from "./pages/Home";


import Login from './pages/Login';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import HomeLayout from "./layouts/HomeLayout";


import Recovery from "./pages/Recovery";
import { checkUserSession } from "./redux/User/user.action";
import { useDispatch } from "react-redux";
import Dashboard from "./pages/Dashboard";
import ProductList from "./pages/ProductList";
import WithAuth from "./hoc/withAuth";
import Admin from "./pages/Admin";
import WithAdminAuth from "./hoc/withAdminAuth";

import AdminToolbar from "./components/AdminToolbar";

import Reset from "./pages/Reset";
import ProductDetails from "./pages/ProductDetails";
import ScrollToTop from "./components/ScrollToTop";



const App = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    
  dispatch(checkUserSession());

    
    
  }, []);


 


    return <>
    
    <Router>
      <ScrollToTop>
      <AdminToolbar/>
    <Switch>
        <Route exact path="/" 
        render={() => (
          <HomeLayout >
            <Home/>
          </HomeLayout>
          
        )}  />
       <Route exact path="/products" 
        render={() => (
          <HomeLayout >
            <ProductList/>
          </HomeLayout>
          
        )}  />

        <Route path="/products/:filterType" 
        render={() => (
          <HomeLayout >
            <ProductList/>
          </HomeLayout>
          
        )}  />

        <Route path="/product/:productID" 
           render={() =>   (
            <MainLayout >
              <ProductDetails/>
            </MainLayout>
            
          )}  />
       
        <Route path="/login" 
           render={() =>   (
            <MainLayout >
              <Login/>
            </MainLayout>
            
          )}  />

        <Route path="/recovery" 
        render={() =>  (
          <MainLayout >
             <Recovery/>
          </MainLayout>
         
        )}  />

        <Route path="/dashboard" 
        render={() =>  (
          <WithAuth>

            <MainLayout >
              <Dashboard/>
            </MainLayout>
          </WithAuth>
          
         
        )}  />

        <Route path="/admin" 
        render={() =>  (
          <WithAdminAuth>
            
             <Admin/>
          
         

          </WithAdminAuth>
          
        )}  />
           <Route path="/reset" 
        render={() =>  (
          <WithAuth>

            <MainLayout >
              <Reset/>
            </MainLayout>
          </WithAuth>
          
         
        )}  />
        
    </Switch>
    </ScrollToTop>
    </Router>
    
    
  </>;
  
    
};


export default App;