import React from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";

const Container = styled.div`
position: fixed;
top: 0; left: 0;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,.3);
center ${mobile({ width: "75%" })}
  
 
`;

const Wrapper = styled.div`
position: absolute;
top: 100%; left: 50%;
-webkit-transform: translate(-50%, -50%);
-moz-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
background-color: white;
width: 95%;
padding: 2rem;
max-width: 60rem;
height: auto%;
min-height: 40.0rem;
    
    ${mobile({ width: "75%" })}
`;




const Modal = ({ hideModal, toggleModal, children }) => {
  if (hideModal) return null;

  return (
    <>
      <Container onClick={() => toggleModal()} />
      <Wrapper>
        {children}
      </Wrapper>
    </>
  );
}

export default Modal;