import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from "styled-components";

import { fetchProductsStart } from '../redux/Products/products.action';
import Product from './Product';
import FormSelect1 from '../components/FormSelect1';
import {mobile} from "../responsive";
import { Search} from '@material-ui/icons';




const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
 
    alignItems: center;
    justifyContent: center;
    
`;

const Container1 = styled.div`
    padding: 20px;
    display: flex;
    
    
    justify-content: space-between;
    ${mobile({ flexDirection: 'column'})}
    
   
    
`;
const Container2 = styled.div`
    padding: 20px;
    
    flex-wrap: wrap;
 
    alignItems: center;
    justifyContent: center;
    
`;

const SearchContainer = styled.div`
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    marginRight: 150px;
    padding: 5px;
    
    
    ${mobile({ marginRight: "5px" })}
    
`;

const SearchBar = styled.input`
    border:none;
    outline: none;
    width: 400px;
    ${mobile({ width: '250' })}
    
`;

const mapState= ({ productsData }) => ({
    products: productsData.products
});

const Products = ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { filterType } = useParams();
    const { products } = useSelector(mapState);
    const [query, setQuery] = useState("");

    
    
    useEffect(() => {
        dispatch(
            fetchProductsStart({ filterType }) 
        )
    }, [filterType]);

    const handleFilter = (e) => {
        const nextFilter = e.target.value;
        history.push(`/products/${nextFilter}`);

    };


   
   

    if (!Array.isArray(products)) return null;
    

    const configFilters = {
        defaultValue : filterType,
        options: [{
            name: 'Mostra tutti',
            value: ''
        }, {
            name: 'Hobbystica feltro, stoffe e gomma',
            value: 'Hobbystica feltro, stoffe e gomma'
        }, {
            name: 'Hobbystica prodotti vari',
            value: 'Hobbystica prodotti vari' 
        }, {
            name: 'Hobbystica articoli da decorare',
            value: 'Hobbystica articoli da decorare' 
        }, {
            name: 'Hobbystica Natale',
            value: 'Hobbystica Natale' 
        },
        {
            name: 'Belle arti',
            value: 'Belle arti'
        }],
        handleChange: handleFilter
    };


   

    if (products.length < 1) {
        return (
            <Container2 >
                <Container1>
                    <FormSelect1 {...configFilters}/>
                    
                    <SearchContainer>
                        <SearchBar placeholder="Cerca prodotto" 
                        onChange={ e => setQuery(e.target.value.toLowerCase())}/>
                        <Search style={{color: "gray", fontSize:16}}/>
                    </SearchContainer>
                    
                </Container1>
                
                <Container2>
                    <p>
                        Nessun prodotto trovato
                    </p>
                </Container2>
                
            </Container2>
        );
    }

   

  
   

    return (

        <Container2>

       <Container1>
        <FormSelect1 {...configFilters}/>
      
        <SearchContainer>
            <SearchBar placeholder="Cerca prodotto"
            onChange={ e => setQuery(e.target.value.toLowerCase())}/>
            <Search style={{color: "gray", fontSize:16}}/>
        </SearchContainer>

        </Container1>     
      
       
       <Container>
       {products.filter((product) => product.productName.concat(' '+product.productCategory).concat(' '+product.productCategory).concat(' '+product.productName).concat(' '+product.productCategory).concat(' '+product.productName).concat(' '+product.productName).concat(' '+product.productCategory).concat(' '+product.productName).toLowerCase().includes(query)).map((product, pos) => {
        
                const { productPhoto, 
                   
                    
                    productPrice,
                    productName,
                    
                    
                    
                
                } = product;
                if (!productPhoto || !productName ||
                    typeof productPrice === 'undefined') return null;

                const configProduct = {
                    ...product
                };
                return(
                    <div >
                        
                        <Product  {...configProduct} />
                        
                        
                      

                    </div>
                );
            })}
                
            
       
                </Container>
       
        

        </Container2>
    );
};

export default Products;
