import React, { useState, useEffect }from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";
// import NavbarMenu from '../components/Navbar';
import { Link, useHistory } from 'react-router-dom';


import FormInput from '../components/FormInput';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  withRouter } from "react-router-dom";
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { emailSignInStart} from '../redux/User/user.action';


// import { updateProfile } from '@firebase/auth';



const Container = styled.div`
  width: 100vw;
  height: 50vh;
  
  background-size: cover;
 
`;

const Wrapper = styled.div`
    width: 40%;
    padding: 10px;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    
    
    ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  padding: 20px;
`;

const Form = styled.form`
  
  display: flex;
  flex-direction: column;
`;



const ButtonSignInSite = styled.button`
    font-size: 20px;
    line-height: 1;
    font-weight: 300;
    margin: auto;
    width: 290px;
    border: none;
    padding: 15px 20px;
    background-color: #ff7600;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    outline: none;
    text-align: center;
    display: block;
    border-radius: 50px
`;



const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const Login = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector(mapState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')

  useEffect (() => {
    if (currentUser) {
      
      resetForm();
      
      history.push('/');
      
    }
  }, [currentUser]);

  const resetForm = () => {
    setEmail('');
    setPassword('');
  };

  
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(emailSignInStart({email, password}));
    

    
  }
 

    
    return (
      <Container>
        
          <Wrapper>
            <ToastContainer/>
              <Title>Accedi</Title>
                  <Form onSubmit={handleSubmit}>

                    <FormInput
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Email"
                      handleChange={e => setEmail(e.target.value)}

                    />

                      <FormInput
                      type="password"
                      name="password"
                      value={password}
                      placeholder="Password"
                      handleChange={e => setPassword(e.target.value)}

                    />
                    
                      <ButtonSignInSite type="submit">Accedi</ButtonSignInSite>
                   
                      <Link to="/recovery" style={{ textDecoration: 'underline', color:"black", fontSize:"12px", margin:"auto", cursor:"pointer" }}>Non ti ricordi la password?</Link>
                    
                     
                      </Form>
                      
                  
              
              
          </Wrapper>
          
      </Container>
  );
  
    
}

export default Login;
