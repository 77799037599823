import React from 'react';
import styled from "styled-components";
import {mobile} from "../responsive";

const Container = styled.div`
    height: 50px;
    ${mobile({ height: "180px" })}
   
    background-color: #ff7600;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
   
`;

const Announcement = () => {
    return (
        <Container>
            <h1 style={{fontSize: "24px", margin: "20px"}}>
            Guarda i prodotti della nostra vetrina online, prenota un appuntamento in negozio o prenotali su Whatsapp!
                </h1>
        </Container>
    );
}

export default Announcement;
