import React, { useState, useEffect } from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";
import FormInput from '../components/FormInput';
import Footer from '../components/Footer';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';



import { useHistory } from 'react-router-dom';
import { resetPasswordStart, resetUserState } from '../redux/User/user.action';
const Container = styled.div`

  width: 100vw;
  height: 80vh;
  
 
`;

const Wrapper = styled.div`
    width: 40%;
    padding: 20px;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 160px;
    
    
    ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  padding: 20px;
`;

const Form = styled.form`
  
  display: flex;
  flex-direction: column;
`;


const Button = styled.button`
    font-size: 20px;
    line-height: 1;
    font-weight: 300;
    margin: auto;
    width: 290px;
    border: none;
    padding: 15px 20px;
    background-color: #ff7600;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    outline: none;
    text-align: center;
    display: block;
`;

const mapState = ({ user }) => ({
    resetPasswordSuccess: user.resetPasswordSuccess,
    userErr: user.userErr
});

const Reset = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetPasswordSuccess, userErr } = useSelector(mapState);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState());
      history.push('/login');
    }

  }, [resetPasswordSuccess]);


  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }

  }, [userErr]);

    
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(resetPasswordStart({ email }));
  }
      
    return (
        <Container>
            <Wrapper>
                
                <ToastContainer/>
                <Title   Title>Inserisci l'indirizzo email usato per l'iscrizione</Title>
                    <Form onSubmit={handleSubmit}>
                        <FormInput
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        handleChange={e => setEmail(e.target.value)}

                        />

                        <Button type="submit" disabled={!email}>
                            Invia email
                        </Button >
                        <h1 type="submit" style={{ padding: "20px", fontSize:"14px", margin:"auto" }}>
                            Ti invieremo un'email per cambiare la password
                        </h1>


            </Form>

            </Wrapper>
            <Footer/>
            
        </Container>
    );
}

export default Reset;




