import React from 'react';




const FormInput = ({ handleChange, label, ...otherProps }) => {
    return (
        <div>
            {label && (
                <label>
                    {label}
                </label>
            )}

            <input style={{ 
                width: "350px",
                fontSize: "20px",
                ineHeight: "1",
                fontWeight: "300",
                display: "block",
                margin: "10px auto",
                padding: "15px 20px",
                textAlign: "center",
            }}
            
            onChange={handleChange} {...otherProps} />
            
            
        </div>
    );
}

export default FormInput;
