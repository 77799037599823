import React from 'react';
import Announcement from '../components/Announcement';
import Categories from '../components/Categories';

import Slider from '../components/Slider';
import PopularProducts from '../components/PopularProducts';

import Footer from '../components/Footer';

// 


const Home = () => {
    return (
        <div >
           
           
           <Announcement/>
          
            <Slider/>
            <Categories/>
            {/* <PopularProducts/> */}
            
            <Footer/>
        </div>
    );
}

export default Home;
