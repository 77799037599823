import React from 'react';
import styled from "styled-components";
import {mobile} from "../responsive";
import { Link } from "react-router-dom";


const Container = styled.div`
    height: 75px;
    position: sticky;
    
    justify-content: space-between;
    padding: 10px;
    top: 0;
    z-index: 999;
    background-color: #ff7600;
    ${mobile({ height: "70px",
    position: "sticky",
    
   

        })}
    
    

`;

    




const Wrapper = styled.div`
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mobile({ padding: "10px 0px", position: "sticky" })}
    position: sticky;
    top: 0;
    z-index: 999;
    


`;

const Left = styled.div`
flex: 1;
display: flex;
align-items: center;
`;

const Logo = styled.h1`
    font-weight: bold;
    flex: 1;
    display: flex;
    ${mobile({ fontSize: "12px" })}
    position: sticky;
    top: 0;
    z-index: 999;
    margin: 10px;
`;
const ProprietaryName = styled.h6`
flex: 1;
display: flex;
margin-top:30px;
margin-left:5px;

${mobile({ marginTop: "20px" })}

`;
const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top:20px
`;







const Navbar = props => {

   
   

  
   
  return (
        <Container> 
            <Wrapper>
                <Left>
                    {/* <Language>IT</Language> */}
                        <Logo >
                        <h2 style={{marginTop: 10}}>DEKORANDO</h2>
                        <ProprietaryName >di Renesto Manuela</ProprietaryName>
                        </Logo>
                        
                </Left>
                
                
                {/* <SearchOutlined fontSize='large' style={{ cursor: "pointer", display:"flex"}}/> */}
              
                
                    <Right>
                        
                        <Link to="/" style={{ textDecoration: 'none', color:"black" }}>
                            <Logo style={{marginRight: 30}}>
                                Home
                            </Logo>
                            
                        </Link>
                    </Right>
               
               
            </Wrapper>
        </Container>
    );
};






export default Navbar;
