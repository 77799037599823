
import React from 'react';
import  styled  from 'styled-components';
import {mobile} from "../responsive";
import { Link } from 'react-router-dom';

const Container = styled.div`
    flex: 1;
    margin: 3px;
    height: 70vh;
    position: relative;
    

`;
const Image = styled.img`
    width: 100%;
    height: 85%;
    object-fit: cover;
    ${mobile({ height: "350px"})}
`;
const Info = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

`;
const Title = styled.h1`
    color: black;
    margin: 40px;
    font-size: 18px;
    font-weight: 2000,
   
`;
const Button = styled.button`
    border: none;
    padding: 10px;
    background-color: white;
    color: gray;
    cursor: pointer;
    font-weight: 600;
    border-radius: 30px;
    margin-top: 10px
    
`;

const TitleContainer = styled.div`
height: 140px;
width: 80%; 
margin-top: 180px;
background-color: white;
border-radius: 20px;
justify-content: center;
align-content: center;
align-items: center;
align-self: center;
${mobile({ height: "110px", marginTop: "140px"})}
    
`;



const CategoryItem = ({item}) => {
    return (
        <Container>
            <Image src={item.img}/>
            <Info>
                <TitleContainer>
                <Title>{item.title}</Title>
                </TitleContainer>
                <Link to={item.page}>
                <Button>VISUALIZZA PRODOTTI</Button>
                </Link>
                
            </Info>
        </Container>
    )
}

export default CategoryItem
